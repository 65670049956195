<template>
    <v-container style="display: flex; flex-direction: row">
        <div
            class="container-bg"
            style="
                width: 50vw;
                display: block;
                margin: auto;
                margin-right: 10px;
            "
        >
            <div class="container-fg">
                <v-overlay :absolute="true" :value="loading">
                    <v-progress-circular
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
                <div style="display: flex; flex-direction: row">
                    <img
                        style="width: 130px; margin-bottom: 1%"
                        src="../../assets/logos/getac-logo.svg"
                    />
                    <div
                        class="text-h7 nav-font pl-1"
                        style="margin-top: 14px; font-weight: 400"
                        >VIDEO PARTS ORDER</div
                    >
                </div>
                <v-spacer></v-spacer>
                <v-divider
                    style="
                        margin-top: 10px;
                        margin-bottom: 10px;
                        border-color: gray;
                    "
                />
                <h1 style="font-size: 36px; letter-spacing: -0.08rem">
                    Shipping Details
                </h1>
                <p>Please confirm or fill out shipping details below.</p>
                <v-card
                    dark
                    style="background-color: transparent; padding: 0px"
                    elevation="0"
                    v-bind="$attrs"
                    :disabled="false"
                    @keypress.enter="save"
                >
                    <v-form ref="form" v-model="formValid">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="userStoredContact.company"
                                    :rules="[
                                        formRules.maxLength(35, 'characters')
                                    ]"
                                    dark
                                    dense
                                    label="Company"
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top: -25px">
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="userStoredContact.firstName"
                                    :rules="[
                                        formRules.required,
                                        formRules.maxLength(35, 'characters')
                                    ]"
                                    dark
                                    dense
                                    label="First Name"
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="userStoredContact.lastName"
                                    :rules="[
                                        formRules.required,
                                        formRules.maxLength(35, 'characters')
                                    ]"
                                    dark
                                    dense
                                    label="Last Name"
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top: -25px">
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="userStoredContact.email"
                                    :rules="[
                                        formRules.required,
                                        formRules.maxLength(35, 'characters')
                                    ]"
                                    dense
                                    dark
                                    label="Email"
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="userStoredContact.phone"
                                    :rules="[
                                        formRules.required,
                                        formRules.maxLength(25, 'characters')
                                    ]"
                                    dense
                                    dark
                                    label="Phone"
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-card-text class="mt-0" style="padding: 0px">
                            <v-text-field
                                ref="address-line1"
                                v-model="userStoredAddress.line1"
                                :rules="[
                                    formRules.required,
                                    formRules.maxLength(35, 'characters')
                                ]"
                                v-bind="$attrs"
                                dense
                                outlined
                                label="Address Line 1"
                            />
                            <v-text-field
                                ref="address-line2"
                                v-model="userStoredAddress.line2"
                                dense
                                outlined
                                v-bind="$attrs"
                                label="Address Line 2"
                            />
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-autocomplete
                                        ref="country"
                                        v-model="userStoredAddress.country"
                                        v-bind="$attrs"
                                        :items="countries"
                                        dense
                                        outlined
                                        item-text="name"
                                        item-value="countryCode"
                                        label="Country"
                                        placeholder="Country"
                                        @change="countryChanged"
                                    />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-autocomplete
                                        ref="state"
                                        v-model="userStoredAddress.state"
                                        v-bind="$attrs"
                                        item-text="name"
                                        dense
                                        outlined
                                        item-value="stateCode"
                                        :loading="statesLoading"
                                        :disabled="
                                            statesLoading ||
                                            !userStoredAddress.country
                                        "
                                        :items="stateProvinces"
                                        label="State/Province/Region"
                                        placeholder="State"
                                        @change="stateSelected($event)"
                                    />
                                </v-col>
                            </v-row>
                            <v-row style="margin-top: -25px">
                                <v-col cols="12" sm="6">
                                    <v-select
                                        v-if="isApo"
                                        ref="city"
                                        v-model="apoCity"
                                        dense
                                        outlined
                                        :rules="[
                                            formRules.required,
                                            formRules.maxLength(
                                                35,
                                                'characters'
                                            )
                                        ]"
                                        :items="['APO', 'DPO', 'FPO']"
                                        v-bind="$attrs"
                                        label="City"
                                    />
                                    <v-text-field
                                        v-else
                                        ref="city"
                                        v-model="userStoredAddress.city"
                                        :rules="[
                                            formRules.required,
                                            formRules.maxLength(
                                                35,
                                                'characters'
                                            )
                                        ]"
                                        dense
                                        outlined
                                        v-bind="$attrs"
                                        label="City"
                                    />
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        ref="zip"
                                        v-model="userStoredAddress.postalCode"
                                        dense
                                        outlined
                                        :rules="[
                                            formRules.required,
                                            formRules.maxLength(
                                                35,
                                                'characters'
                                            )
                                        ]"
                                        v-bind="$attrs"
                                        label="ZIP / Postal Code"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-form>
                </v-card>
            </div>
        </div>
        <div
            class="container-bg"
            style="
                display: block;
                margin: auto;
                margin-top: 0px;
                margin-left: 0px;
            "
        >
            <v-overlay :absolute="true" :value="loading">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-overlay>
            <div class="container-fg">
                <h1 style="font-size: 36px; letter-spacing: -0.08rem">
                    Your Order
                </h1>
                <p
                    >Please confirm your order or
                    <a @click="$emit('backToCart')">go back to your cart</a>.</p
                >
                <v-divider
                    style="
                        margin-top: -10px;
                        margin-bottom: 15px;
                        border-color: gray;
                    "
                />
                <v-data-table
                    id="request-data-table"
                    style="background-color: transparent"
                    dark
                    :headers="headers"
                    hide-default-footer
                    disable-sort
                    :items="cart"
                    loading-text="Loading..."
                    no-data-text="No items in cart"
                >
                    <template v-slot:item.partDescription="{ item }">
                        <span style="color: #db5120; font-size: 10px">{{
                            item.category
                        }}</span
                        ><br />
                        {{ item.partDescription }}
                    </template>
                </v-data-table>
                <v-divider
                    style="
                        margin-bottom: 1%;
                        border-color: rgb(56, 56, 56);
                        width: 90%;
                    "
                />
                <v-btn
                    color="primary"
                    elevation="0"
                    style="display: block; margin: auto; margin-top: 20px"
                    :disabled="$apollo.loading"
                    @click="createOrder"
                >
                    Create Order
                </v-btn>
            </div>
        </div>
        <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    color="white"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import formRules from '@/utils/form_rules'
import { mapGetters, mapActions } from 'vuex'
import gql from 'graphql-tag'

export default {
    name: `PartsOrderCheckout`,
    components: {},
    props: {
        cart: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            orderItems: [],
            snackbar: false,
            valid: null,
            statesLoading: false,
            loading: false,
            citiesLoading: false,
            states: [],
            selectedState: {},
            snackbarMessage: '',
            customer: {
                contact: {},
                shipping: {}
            },
            isApo: false,
            formRules,
            formValid: false,
            apoCity: '',
            headers: [
                // {
                //     text: 'Category',
                //     value: 'category',
                //     align: 'left'
                // },
                {
                    text: 'Product',
                    value: 'partDescription',
                    align: 'left'
                },
                {
                    text: 'Part #',
                    value: 'partNumber',
                    align: 'left'
                },
                {
                    text: 'Quantity',
                    value: 'quantity',
                    align: 'center'
                }
            ]
        }
    },
    computed: {
        userStoredAddress() {
            if (this.$auth.user.address && this.$auth.user.address.shipping) {
                return {
                    city: this.$auth.user.address.shipping.city,
                    country: this.$auth.user.address.shipping.country,
                    line1: this.$auth.user.address.shipping.line1,
                    line2: this.$auth.user.address.shipping.line2,
                    postalCode: this.$auth.user.address.shipping.postalCode,
                    state: this.$auth.user.address.shipping.state
                }
            } else return {}
        },
        userStoredContact() {
            if (this.$auth.user) {
                return {
                    company: this.$auth.user.company,
                    email: this.$auth.user.email,
                    firstName: this.$auth.user.firstName,
                    lastName: this.$auth.user.lastName,
                    phone: this.$auth.user.phone,
                    phoneCountryCode: this.$auth.user.phoneCountryCode
                }
            } else return {}
        },
        countries() {
            return this.$store.getters[`location/getCachedCountries`]
        },
        cities() {
            return this.$store.getters[`location/getCachedCities`]({
                countryCode: this.userStoredAddress.country,
                stateCode: this.userStoredAddress.state
            })
        },
        stateProvinces() {
            if (this.$auth.user.address && this.$auth.user.address.shipping) {
                this.countryChanged(this.userStoredAddress.country)
            }
            return this.$store.getters[`location/getCachedStates`]({
                countryCode: this.userStoredAddress.country
            })
        }
    },
    methods: {
        stateSelected(v) {
            const stateObj = this.states.find(
                (element) => element.stateCode === v
            )
            this.selectedState = stateObj
            this.isApo = stateObj.apo
        },
        createOrder() {
            const isValid = this.$refs.form.validate()
            if (isValid) {
                this.loading = true
                const allItems = this.checkoutItems(this.cart)
                const checkoutItems = [].concat.apply([], allItems)
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation CreatePartsOrder(
                                $newRma: PartsOrderInputDTO!
                                $adminOverrides: SMAllowedRMAOverrides
                            ) {
                                CreatePartsOrder(
                                    newRMA: $newRma
                                    adminOverrides: $adminOverrides
                                ) {
                                    rmaBatchId
                                    success
                                    uids
                                    error
                                }
                            }
                        `,
                        variables: {
                            newRma: {
                                rmaType: 'GVS_PARTS_ORDER',
                                customer: {
                                    shipping: {
                                        address: {
                                            company:
                                                this.userStoredContact.company,
                                            line1: this.userStoredAddress.line1,
                                            city: this.isApo
                                                ? this.apoCity
                                                : this.userStoredAddress.city,
                                            state: this.userStoredAddress.state,
                                            country:
                                                this.userStoredAddress.country,
                                            postalCode:
                                                this.userStoredAddress
                                                    .postalCode
                                        },
                                        contact: {
                                            firstName:
                                                this.userStoredContact
                                                    .firstName,
                                            lastName:
                                                this.userStoredContact.lastName,
                                            email: this.userStoredContact.email,
                                            company:
                                                this.userStoredContact.company,
                                            phone: this.userStoredContact.phone
                                        }
                                    }
                                },
                                items: checkoutItems
                            }
                        }
                    })
                    .then((data) => {
                        if (data.data.CreatePartsOrder.success === true) {
                            this.$emit(
                                'orderSuccess',
                                data.data.CreatePartsOrder
                            )
                        } else {
                            this.snackbar = true
                            this.snackbarMessage = 'Error Creating Parts Order'
                        }
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarMessage = 'Error Creating Parts Order'
                    })
            } else {
                this.snackbar = true
                this.snackbarMessage =
                    'Please fill out all of the required fields'
            }
        },
        checkoutItems(items) {
            return items.map((item) => {
                const lineItem = item
                return {
                    pn: lineItem.partNumber,
                    quantity: parseInt(lineItem.quantity)
                }
            })
        },
        async countryChanged(v) {
            if (v) {
                this.statesLoading = true
                try {
                    const states = await this.$store.dispatch(
                        `location/cacheStates`,
                        {
                            countryCode: v
                        }
                    )
                    this.states = states
                    this.statesLoading = false
                } catch {
                    this.statesLoading = false
                }
            } else this.customer.state = ``
        },
        async getCities(event) {
            this.citiesLoading = true
            try {
                const [countryCode, stateCode] = event
                await this.$store.dispatch(`location/cacheCities`, {
                    countryCode,
                    stateCode
                })
            } catch {
                this.citiesLoading = false
            } finally {
                this.citiesLoading = false
            }
        }
    }
}
</script>

<style>
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td,
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:not(.v-data-table__mobile-row),
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > th:last-child,
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > th:not(.v-data-table__mobile-row),
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr:last-child
    > th {
    border: none !important;
    background-color: transparent !important;
}
#request-data-table .theme--dark.v-data-table {
    background-color: transparent !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-spacing: 0 0.5rem !important;
}
#request-data-table tr:not(.v-data-table-header > tr) {
    margin: auto;
    padding: 10px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
    margin-bottom: 10px;
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
}
#request-data-table .theme--dark.v-data-table {
    background-color: transparent !important;
    color: #fff;
}
#request-data-table > .v-data-table__wrapper > table {
    border-spacing: 0 0.5rem;
    background-color: rgb(63, 63, 63);
}
</style>
