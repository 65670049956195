<template>
    <div
        class="container-bg"
        style="
            margin: auto;
            display: block;
            background: rgba(20, 20, 20, 0.8);
            width: 50%;
        "
    >
        <div class="container-fg">
            <v-card dark style="background-color: transparent" elevation="0">
                <v-card-title style="background-color: transparent">
                    <h1
                        style="
                            font-size: 36px;
                            letter-spacing: -0.08rem;
                            color: white;
                        "
                    >
                        Select Quantity <br />
                        <span style="font-size: 25px"
                            >({{ selectedItem.partDescription }})</span
                        >
                    </h1>
                </v-card-title>
                <v-card-text> </v-card-text>
                <v-divider style="margin-bottom: 20px" color="gray"></v-divider>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-if="!updateView"
                            v-model="selectedQuantity"
                            outlined
                            type="number"
                            label="Desired Quantity (Max 5)"
                            @change="checkMax"
                        ></v-text-field>
                        <v-text-field
                            v-if="updateView"
                            v-model="cartQty"
                            outlined
                            type="number"
                            label="Desired Quantity (Max 5)"
                            @change="checkMax"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-divider style="margin-bottom: 20px" color="gray"></v-divider>

                <div>
                    <base-btn color="primary" text @click="$emit('cancel')">
                        Cancel
                    </base-btn>
                    <base-btn
                        v-if="!updateView"
                        class="elevation-0 dialog-btns"
                        style="float: right"
                        @click="confirm"
                    >
                        Add {{ selectedQuantity }} To Cart
                    </base-btn>
                    <base-btn
                        v-if="updateView"
                        class="elevation-0 dialog-btns"
                        style="float: right"
                        @click="update"
                    >
                        Update
                    </base-btn>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectQuantity',
    props: {
        selectedItem: {
            type: Object,
            required: true
        },
        selectedTpm: {
            type: String,
            required: true
        },
        updateView: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            selectedQuantity: 1,
            local: null
        }
    },
    watch: {
        selectedItem: {
            immediate: true,
            handler() {
                this.local = { ...this.selectedItem }
                this.cartQty = this.local.quantity
            }
        }
    },
    methods: {
        confirm() {
            this.$emit('confirm', {
                item: this.selectedItem,
                selectedQuantity: this.selectedQuantity
            })
            this.selectedQuantity = 1
        },
        update() {
            this.$emit('update', this.cartQty)
        },
        checkMax() {
            if (!this.updateView) {
                if (parseInt(this.selectedQuantity) > 5) {
                    this.selectedQuantity = 5
                }
            } else {
                if (parseInt(this.cartQty) > 5) {
                    this.cartQty = 5
                }
            }
        }
    }
}
</script>
