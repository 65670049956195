<template>
    <v-container>
        <div v-if="videoSerialPermission">
            <v-alert
                v-if="partsOrderWarningMessage"
                border="left"
                color="blue-grey"
                dark
                dense
                class="mt-5"
                type="info"
            >
                <!--Parts Order will not be availabe between Dec 2 and 8 Dec because of
            year end inventory reconciliation -->
                {{ partsOrderWarningMessage }}
            </v-alert>
            <success v-if="orderSuccess" :rma-details="rmaDetails"></success>
            <checkout
                v-if="checkoutView"
                :cart="cart"
                @backToCart="checkoutView = false"
                @orderSuccess="completedOrder"
            ></checkout>
            <div v-if="!checkoutView && !orderSuccess && !isPartOrderPaused" class="container-bg">
                <div class="container-fg">
                    <v-card-title style="padding: 5px 5px">
                        <div style="display: flex; flex-direction: row">
                            <img style="width: 130px; margin-bottom: 1%" src="../../assets/logos/getac-logo.svg" />
                            <div class="text-h7 nav-font pl-3" style="margin-top: 4.2%; font-weight: 400"
                                >VIDEO PARTS ORDER</div
                            >
                        </div>
                        <v-spacer></v-spacer>
                        <v-text-field
                            id="data-text-field"
                            v-model="search"
                            style="width: 10px; margin-bottom: 15px; margin-top: 15px"
                            append-icon="mdi-magnify"
                            label="Search Parts"
                            single-line
                            dense
                            hide-details
                            clearable
                            outlined
                            dark
                        ></v-text-field>
                    </v-card-title>
                    <v-divider style="margin-bottom: 1%; border-color: gray" />
                    <v-expansion-panels v-model="panel" class="expanded-panels" dark mandatory accordion>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="expansion-header-first" expand-icon="">
                                <h1 v-if="partsView" style="font-size: 36px; letter-spacing: -0.08rem">
                                    Viewing Video Inventory
                                </h1>
                                <h1 v-if="!partsView" style="font-size: 36px; letter-spacing: -0.08rem">
                                    Viewing Cart
                                </h1>
                                <v-btn
                                    max-width="200px"
                                    color="primary"
                                    elevation="0"
                                    outlined
                                    class="mr-2"
                                    @click="
                                        partsView = !partsView
                                        tab = partsView ? 0 : 1
                                    "
                                    >{{ partsView ? 'Cart' : 'Available Parts' }}</v-btn
                                >
                                <v-btn max-width="200px" color="primary" elevation="0" @click="checkoutView = true"
                                    >Checkout</v-btn
                                >
                            </v-expansion-panel-header>
                            <v-expansion-panel-content style="padding: 25px; border-radius: 0px" class="container-fg">
                                <v-row>
                                    <v-col cols="6" style="margin-top: -10px !important; margin-bottom: 10px">
                                        <v-tabs v-model="tab" class="request-tab-top">
                                            <v-tab @click="partsView = true">
                                                <div class="text-h7 nav-font">Available Parts</div>
                                            </v-tab>
                                            <v-tab @click="partsView = false">
                                                <v-badge :content="cart.length" color="error">
                                                    <div class="text-h7 nav-font">Cart</div>
                                                </v-badge>
                                            </v-tab>
                                        </v-tabs>
                                    </v-col>
                                </v-row>
                                <v-overlay :absolute="true" :value="loading || $apollo.loading">
                                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                </v-overlay>
                                <v-data-table
                                    v-if="partsView"
                                    id="request-data-table"
                                    dark
                                    :headers="headers"
                                    :items="availableParts"
                                    :items-per-page="10"
                                    :search="search"
                                    :no-data-text="!selectedTpm ? 'No results available' : 'No results available'"
                                    no-results-text="No results available"
                                >
                                    <template v-slot:item.photo="{ item }">
                                        <v-img
                                            max-width="150"
                                            style="display: block; margin: auto"
                                            :src="`https://rma.getac.com/MSS/downloadFileAction.do?REFERENCE_ID=PN_PHOTO_${item.partNumber}`"
                                        ></v-img>
                                    </template>
                                    <template v-slot:item.action="{ item }">
                                        <v-btn
                                            v-if="!existsInCart(item)"
                                            color="primary"
                                            elevation="0"
                                            outlined
                                            :disabled="wrongTpm"
                                            @click="
                                                selectItem(item)
                                                updateView = false
                                            "
                                            ><v-icon>{{ mdiCart }}</v-icon></v-btn
                                        >
                                        <v-btn
                                            v-if="existsInCart(item)"
                                            color="primary"
                                            elevation="0"
                                            outlined
                                            :disabled="true"
                                            >In Cart</v-btn
                                        >
                                    </template>
                                </v-data-table>
                                <v-data-table
                                    v-if="!partsView"
                                    id="request-data-table"
                                    dark
                                    :headers="cartHeaders"
                                    :items="cart"
                                    :items-per-page="10"
                                    :search="search"
                                    no-data-text="No items in cart"
                                    no-results-text="No results available"
                                >
                                    <template v-slot:item.action="{ item }">
                                        <v-btn
                                            color="primary"
                                            elevation="0"
                                            outlined
                                            @click="
                                                selectItem(item)
                                                updateView = true
                                                selectedTpm = 'Ivy'
                                            "
                                            >Update</v-btn
                                        >
                                    </template>
                                    <template v-slot:item.remove="{ item }">
                                        <v-btn color="primary" elevation="0" outlined @click="removeFromCart(item)"
                                            ><v-icon>{{ mdiCartRemove }}</v-icon></v-btn
                                        >
                                    </template>
                                </v-data-table>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </div>
            <v-dialog v-model="chooseQuantity" style="width: 50%" content-class="elevation-0">
                <select-quantity
                    :selected-item="selectedItem"
                    :selected-tpm="selectedTpm"
                    :update-view="updateView"
                    @cancel="chooseQuantity = false"
                    @update="updateQty($event, selectedItem)"
                    @confirm="confirmAdd($event)"
                ></select-quantity>
            </v-dialog>
            <v-snackbar v-model="snackbar" right>
                {{ snackbarMessage }}

                <template v-slot:action="{ attrs }">
                    <v-btn text v-bind="attrs" color="white" @click="snackbar = false"> Close </v-btn>
                </template>
            </v-snackbar>
        </div>
        <v-card v-else style="margin-top: 25px">
            <v-card-title class="text-h5"> You do not have access to use this feature. </v-card-title>
        </v-card>
    </v-container>
</template>

<script>
import { mdiCart, mdiCartRemove } from '@mdi/js'
import gql from 'graphql-tag'
import SelectQuantity from './select_quantity.vue'
import Checkout from './checkout.vue'
import Success from './success.vue'
import { rolesMap } from '@/config'

export default {
    name: 'GVSPartsOrder',
    components: { SelectQuantity, Checkout, Success },
    props: {},
    apollo: {
        queryTPMInv: {
            variables() {
                return {
                    tpm: 'Ivy',
                    isVideoOnly: true
                }
            },
            query: gql`
                query QueryTPMInv($tpm: String!, $isVideoOnly: Boolean) {
                    queryTPMInv(tpm: $tpm, isVideoOnly: $isVideoOnly) {
                        category
                        partDescription
                        partNumber
                        invQty
                    }
                }
            `,
            update(data) {
                this.availableParts = data.queryTPMInv
                this.loading = false
            }
        }
    },
    data() {
        return {
            dialog: false,
            tab: null,
            search: '',
            availableParts: [],
            chooseQuantity: false,
            partsView: true,
            checkoutView: false,
            updateView: null,
            snackbar: false,
            snackbarMessage: '',
            loading: false,
            panel: 0,
            selectedItem: {},
            supplySiteOptions: ['Ivy', 'GVS'],
            selectedTpm: 'Ivy',
            wrongTpm: false,
            partsOrderWarningMessage: '',
            isPartOrderPaused: false,
            mdiCart,
            mdiCartRemove,
            orderSuccess: false,
            rmaDetails: {},
            cart: [],
            headers: [
                {
                    text: 'Photo',
                    align: 'start',
                    sortable: false,
                    value: 'photo'
                },
                {
                    text: 'Category',
                    align: 'start',
                    sortable: false,
                    value: 'category'
                },
                {
                    text: 'Part Description',
                    align: 'start',
                    value: 'partDescription',
                    sortable: false
                },
                {
                    text: 'Part Number',
                    align: 'center',
                    value: 'partNumber',
                    sortable: false
                },
                {
                    text: '',
                    align: 'center',
                    value: 'action',
                    sortable: false
                }
            ],
            cartHeaders: [
                {
                    text: 'Category',
                    align: 'start',
                    sortable: false,
                    value: 'category'
                },
                {
                    text: 'Part Description',
                    align: 'start',
                    value: 'partDescription',
                    sortable: false
                },
                {
                    text: 'Part Number',
                    align: 'center',
                    value: 'partNumber',
                    sortable: false
                },
                {
                    text: 'Quantity',
                    align: 'center',
                    value: 'quantity',
                    sortable: false
                },
                {
                    text: '',
                    align: 'end',
                    value: 'action',
                    sortable: false
                },
                {
                    text: '',
                    align: 'start',
                    value: 'remove',
                    sortable: false
                }
            ]
        }
    },
    computed: {
        isTraining() {
            return this.$auth.user && this.$auth.user.email === 'getaccomputing@usgetac.com'
        },
        videoSerialPermission() {
            return this.$auth.allRoles(rolesMap.videoSerialPermission)
        }
    },
    created() {
        // query parts order warning period
        this.$apollo
            .query({
                query: gql`
                    query QueryPartsOrderWarning($reportId: String!, $params: String) {
                        queryCommonReport(reportId: $reportId, params: $params)
                    }
                `,
                variables: {
                    reportId: 'QUERY_PARTS_ORDER_WARNING',
                    params: '{}'
                }
            })
            .then((data) => {
                const parsed = data.data.queryCommonReport.map((e) => JSON.parse(e))
                if (parsed && parsed.length > 0) {
                    this.isPartOrderPaused = parsed[0].isPaused
                    if (parsed[0].isWarning || parsed[0].isPaused) this.partsOrderWarningMessage = parsed[0].message
                }
            })
    },
    methods: {
        add(item) {
            this.cart.push(item)
        },
        update(item, newQty) {
            const found = this.cart.findIndex((e) => e.partNumber === item.partNumber)
            const add = {
                category: item.category,
                invQty: item.invQty,
                partDescription: item.partDescription,
                partNumber: item.partNumber,
                quantity: parseInt(newQty),
                tpm: item.tpm
            }
            this.cart.splice(found, 1, add)
        },
        remove(item) {
            const found = this.cart.findIndex((e) => e.partNumber === item.partNumber)
            this.cart.splice(found, 1)
        },
        selectItem(selectedItem) {
            this.selectedItem = {}
            this.chooseQuantity = true
            this.selectedItem = selectedItem
        },
        completedOrder(rma) {
            this.rmaDetails = rma
            this.checkoutView = false
            this.orderSuccess = true
        },
        updateQty(newQty, item) {
            this.update(item, newQty)
            this.chooseQuantity = false
            this.snackbar = true
            this.snackbarMessage = `${item.partDescription} successfully updated.`
        },
        confirmAdd(item) {
            this.add({
                category: item.item.category,
                partNumber: item.item.partNumber,
                partDescription: item.item.partDescription,
                quantity: parseInt(item.selectedQuantity),
                invQty: item.item.invQty,
                tpm: this.selectedTpm
            })
            this.chooseQuantity = false
            this.snackbar = true
            this.snackbarMessage = `${item.item.partDescription} (${item.selectedQuantity}) successfully added to cart.`
            this.selectedItem = {}
        },
        removeFromCart(item) {
            this.remove(item)
            this.snackbar = true
            this.snackbarMessage = `${item.partDescription} has been removed from the cart.`
        },
        existsInCart(item) {
            const result = this.cart.some(function (element) {
                return element.partNumber === item.partNumber
            })
            return result
        },
        checkChange(tpm) {
            const tpmInCart = this.cart.some(function (element) {
                return element.tpm === tpm
            })
            if (!tpmInCart) {
                const current = this.cart[0].tpm
                this.loading = false
                this.wrongTpm = true
                this.snackbar = true
                this.snackbarMessage = `You currently have ${current} inventory in your cart. Please clear your cart of these items in order to add from ${tpm} inventory.`
            } else {
                this.wrongTpm = false
            }
        }
    }
}
</script>

<style>
#request-data-table .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > td:not(.v-data-table__mobile-row),
#request-data-table .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr > th:last-child,
#request-data-table
    .theme--dark.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr
    > th:not(.v-data-table__mobile-row),
#request-data-table .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border: none !important;
    background-color: transparent !important;
}
#request-data-table .theme--dark.v-data-table {
    background-color: transparent !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-spacing: 0 0.5rem !important;
}
#request-data-table tr:not(.v-data-table-header > tr) {
    margin: auto;
    padding: 10px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(155deg, rgba(90, 90, 90, 0.6), rgba(255, 255, 255, 0));
    margin-bottom: 10px;
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
}
#request-data-table .theme--dark.v-data-table {
    background-color: transparent !important;
    color: #fff;
}
#request-data-table > .v-data-table__wrapper > table {
    border-spacing: 0 0.5rem;
    background-color: rgb(63, 63, 63);
}
.theme--dark.v-data-table .v-data-footer {
    border: none;
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(155deg, rgba(90, 90, 90, 0.6), rgba(255, 255, 255, 0));
    /* display: none; */
}
.expansion-header {
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(155deg, rgba(90, 90, 90, 0.6), rgba(255, 255, 255, 0));
}
.expansion-header-first {
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(155deg, rgba(90, 90, 90, 0.6), rgba(255, 255, 255, 0));
}
.expanded-panels .v-expansion-panel-content__wrap {
    padding: 0px;
}
.v-tabs .request-tab-top .theme--dark {
    background-color: transparent !important;
}
.theme--dark.v-tabs > .v-tabs-bar {
    background-color: transparent;
}
</style>
